.monitors-screen{
  padding: 0 20px 20px;
  margin-bottom: 50px;
}

.dashboard-screen-title{
  font-size: 2rem;
  margin: 20px;
  text-align: center;
}

.dashboard-loading-spinner-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.dashboard-loading-spinner{
  width: 4rem;
  height: 4rem;
}

.dashboard-card-main-text{
  font-size: 2rem;
}