.ds-form-group{
  margin: 10px 0;
}

.ds-form-label{
  font-size: 0.9rem;
  margin-left: 3px;
  padding: 0;
}

.ds-form-submit{
  border: none;
  width: 100%;
}

.ds-modal {
  .modal-content{
    background-color: var(--theme-colorscheme-surface);
  }
  .btn-close{
    background-image: var(--theme-modal-close-btn-icon);
  }
  //
}