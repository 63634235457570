

.sidebar{
  width: 225px;
  background-color: var(--theme-colorscheme-surface);
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  margin-right: 50px;

  @media (max-width: 991px) {
    display: none;
  }

  .nav-link{
    color: var(--theme-colorscheme-on-surface);
  }
}

.sidebar-inner{
  position: fixed;
  min-height: calc(100vh - 74px - 44px);
  width: 225px;
  background-color: var(--theme-colorscheme-surface);
  display: flex;
    flex-direction: column;
}

.sidebar-nav-item-0{
  .nav-link{
    font-size: 18pt;
    .sidebar-link-name{
      font-size: 13pt;
    }
  }
}

.sidebar-link-0{
  width: 100%;
  padding: 20px;
  text-decoration: none;
  text-align: center;
  color: rgba(var(--theme-colorscheme-on-surface-rgb),0.6);
  i{

    width: 100%;
    display: block;
  }


}

.sidebar-link-0.active{
  color: var(--theme-colorscheme-on-surface);
  .sidebar-link-name{
    color: var(--theme-colorscheme-on-surface);
  }
  background-color: rgba(var(--theme-colorscheme-on-surface-rgb),0.1);
}



.sidebar-link:hover{
  .sidebar-link-name{
    color: var(--theme-colorscheme-on-surface);
  }
}

.sidebar-nav{
  flex-direction: column;
  flex: 1;
}

.sidebar-bottom-section{
  bottom: 10px;
  width: 100%;
  padding: 20px;
}

.sidebar-bottom-section-wrapper{
  display: flex;
    flex-direction: column;
  flex: 1;
}
.sidebar-bottom-section-filler{
    flex: 1;

}

.sidebar-bottom-section-item{
  width: 100%;
  margin-top: 15px;
}

.sidebar-sub-link-spacer {
  margin-left: 5px;
  border-left: 5px solid var(--theme-colorscheme-secondary);
  margin-top: 2px;
}

.sidebar-nav-item-0.active{
  border-bottom: 5px solid rgba(var(--theme-colorscheme-on-surface-rgb),0.1);
  border-left: 5px solid rgba(var(--theme-colorscheme-on-surface-rgb),0.1);
}

.sidebar-link-1{
  border-left: 10px solid var(--theme-colorscheme-primary) !important;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 2px;
  text-decoration: none;
  font-size: 12pt;
  text-align: center;
  color: rgba(var(--theme-colorscheme-on-surface-rgb),0.6);
  i{
    width: 100%;
    display: block;
  }
  .sidebar-link-name{
    font-size: 10pt;
    text-align: left;
  }
}

.sidebar-link-1.active{
  color: var(--theme-colorscheme-on-surface);
  .sidebar-link-name{
    color: var(--theme-colorscheme-on-surface);
  }
  background-color: rgba(var(--theme-colorscheme-on-surface-rgb),0.1);
}
