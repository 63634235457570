:root {
  overflow-x: hidden;

  --bs-primary: var(--theme-colorscheme-primary);
  --bs-secondary: var(--theme-colorscheme-secondary);
  --bs-success: var(--theme-colorscheme-green);
  --bs-info: var(--theme-colorscheme-blue);
  --bs-warning: var(--theme-colorscheme-yellow);
  --bs-danger: var(--theme-colorscheme-red);
  --bs-body-bg: var(--theme-colorscheme-background);

  --theme-surface-padding: 20px;

  .btn-primary {
    --bs-btn-bg: var(--theme-colorscheme-primary);
    --bs-btn-color: var(--theme-colorscheme-on-primary);
    --bs-btn-border-color: var(--theme-colorscheme-primary);
    --bs-btn-hover-bg: rgba(var(--theme-colorscheme-primary-rgb), 0.6);
    --bs-btn-active-bg: var(--theme-colorscheme-secondary);
    --bs-btn-active-color: var(--theme-colorscheme-on-secondary);
    --bs-btn-active-border-color: var(--theme-colorscheme-primary);
  }

  .btn-secondary {
    --bs-btn-bg: var(--theme-colorscheme-secondary);
    --bs-btn-border-color: var(--theme-colorscheme-secondary);
    --bs-btn-hover-bg: rgba(var(--theme-colorscheme-secondary-rgb), 0.6);
    --bs-btn-active-bg: var(--theme-colorscheme-primary);
    --bs-btn-active-border-color: var(--theme-colorscheme-secondary);
    --bs-btn-color: var(--theme-colorscheme-on-secondary);
  }

  .btn-danger {
    --bs-btn-bg: var(--theme-colorscheme-red);
    --bs-btn-border-color: var(--theme-colorscheme-red);
    --bs-btn-hover-bg: rgba(var(--theme-colorscheme-red-rgb), 0.6);
    --bs-btn-hover-color: var(--theme-colorscheme-on-surface);
    --bs-btn-active-bg: var(--theme-colorscheme-red);
    --bs-btn-active-border-color: var(--theme-colorscheme-red);
    --bs-btn-color: var(--theme-colorscheme-on-red);
  }

  .btn-success{
    --bs-btn-bg: var(--theme-colorscheme-green);
    --bs-btn-border-color: var(--theme-colorscheme-green);
    --bs-btn-hover-bg: rgba(var(--theme-colorscheme-green-rgb), 0.6);
    --bs-btn-hover-border-color: var(--theme-colorscheme-green);
    --bs-btn-hover-color: var(--theme-colorscheme-on-surface);
    --bs-btn-active-bg: var(--theme-colorscheme-green);
    --bs-btn-active-border-color: var(--theme-colorscheme-green);
    --bs-btn-color: var(--theme-colorscheme-on-green);
  }

  .btn-warning{
    --bs-btn-bg: var(--theme-colorscheme-yellow);
    --bs-btn-border-color: var(--theme-colorscheme-yellow);
    --bs-btn-hover-bg: rgba(var(--theme-colorscheme-yellow-rgb), 0.6);
    --bs-btn-hover-border-color: var(--theme-colorscheme-yellow);
    --bs-btn-hover-color: var(--theme-colorscheme-on-surface);
    --bs-btn-active-bg: var(--theme-colorscheme-yellow);
    --bs-btn-active-border-color: var(--theme-colorscheme-yellow);
    --bs-btn-color: var(--theme-colorscheme-on-yellow);
  }

  .btn-info {
    --bs-btn-bg: var(--theme-colorscheme-blue);
    --bs-btn-border-color: var(--theme-colorscheme-blue);
    --bs-btn-hover-bg: rgba(var(--theme-colorscheme-blue-rgb), 0.6);
    --bs-btn-hover-border-color: var(--theme-colorscheme-blue);
    --bs-btn-hover-color: var(--theme-colorscheme-on-surface);
    --bs-btn-active-bg: var(--theme-colorscheme-blue);
    --bs-btn-active-border-color: var(--theme-colorscheme-blue);
    --bs-btn-color: var(--theme-colorscheme-on-blue);
  }

  .form-control {
    --bs-background-color: var(--theme-colorscheme-background);
    --bs-border-color: rgba(var(--theme-colorscheme-primary-rgb), 0.5);
  }

  .form-select {
    --bs-background-color: var(--theme-colorscheme-background);
    --bs-border-color: rgba(var(--theme-colorscheme-primary-rgb), 0.5);
  }

  .accordion {
    --bs-accordion-active-bg: var(--theme-colorscheme-primary);
    --bs-accordion-active-color: var(--theme-colorscheme-on-primary);
    --bs-accordion-border-color: var(--theme-colorscheme-background);
  }

  .accordion-item {
    --bs-accordion-bg: var(--theme-colorscheme-surface);
    --bs-accordion-color: var(--theme-colorscheme-on-surface);
    --bs-accordion-border-color: rgba(var(--theme-colorscheme-primary-rgb), 0.5);
  }

  .accordion-button::after {
    --bs-accordion-btn-icon: var(--theme-accordion-btn-icon);
    --bs-accordion-btn-active-icon: var(--theme-accordion-btn-active-icon);
  }

  .ag-theme-quartz {
    --ag-header-background-color: var(--theme-colorscheme-primary);
    --ag-header-foreground-color: var(--theme-colorscheme-on-primary);
    --ag-background-color: var(--theme-colorscheme-surface);
    --ag-foreground-color: var(--theme-colorscheme-on-surface);
    --ag-font-size: 14px;
    --ag-border-color: var(--theme-colorscheme-gridline);
  }


  background-color: var(--theme-colorscheme-background);
  color: var(--theme-colorscheme-on-background);

  font-family: 'montserrat', 'Roboto', sans-serif;
}
