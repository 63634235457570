
.completion-feedback-action{
  display: block;
  width: 100%;
  margin-top: 10px;
}

.demo-feedback-screen{
  margin-top: 20px;
}

.completion-feedback-list-item-wrapper{
  display: flex;
  flex-direction: row;
}

.completion-feedback-user-request {
  background-color: var(--theme-colorscheme-blue);
  margin-right: 10px;
  padding: 10px;
  border-radius: 10px 10px 10px 0;
}

.completion-feedback-ai-response {
  background-color: var(--theme-colorscheme-pink);
  border-radius: 10px 10px 0 10px;
  padding: 10px;
}

.completion-feedback-conversation-wrapper{
  height: 400px;
}

.completion-feedback-action-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}