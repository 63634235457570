
.ds-log-search-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px;
    border: 1px solid rgba(var(--theme-colorscheme-primary-rgb), 0.5);
    border-radius: 10px;
    width: 100%;
    flex-wrap: wrap;
    grid-gap: 10px;

}


.ds-log-search-bar-element{
  width: 80px;
  display: flex;
  flex-direction: column;
   align-content: center;
  justify-content: center;
}

.ds-log-search-bar-element-large{
  width: 180px;
}

.ds-log-search-bar-search-button-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ds-log-search-snippet{
    width: 100%;
    display: flex;
      flex-direction: row;
    height: 250px;
    margin-top: 10px;
    padding: 20px;

    border: 1px solid rgba(var(--theme-colorscheme-primary-rgb), 0.5);
    border-radius: 10px;
}

.ds-log-search-snippet-content{

}

.ds-log-search-snippet-actions{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    button{
      width: 100%;
        margin-top: 10px;
    }
}

.ds-log-search-snippet-user-request{
    margin-right: 20%;
    background-color: var(--theme-colorscheme-primary);
       color: var(--theme-colorscheme-on-primary);
    padding: 10px;
    border-radius: 10px 10px 0 10px;
}

.ds-log-search-snippet-ai-response{
  margin-left: 20%;
  margin-top: 5px;
  background-color: var(--theme-colorscheme-secondary);
  color: var(--theme-colorscheme-on-secondary);
  padding: 10px;
  border-radius: 10px 10px 10px 0;
}

.ds-log-search-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 1px solid rgba(var(--theme-colorscheme-primary-rgb), 0.5);
    border-radius: 10px;
    margin-top: 10px;
    font-size: 20pt;
}