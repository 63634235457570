.ds-navbar{
  background-color: var(--theme-colorscheme-surface);
  height: 74px;
}

.ds-navbar-collapse{
  margin-top: 0;
  padding: 10px;
}

.navbar-button {
  margin-left: 10px;
  margin-right: 10px;
}

.ds-navbar-brand{
  display: flex;
  align-items: center;
  justify-content: center;
}

.ds-navbar-brand-name {
  font-size: 2rem !important;
  font-weight: 400;
  margin-left: 5px;
  color: var(--theme-colorscheme-primary);
}

.brand-logo {
  height: 42px;
  margin-left: 20px;
}

.ds-screen {
  margin: 0;
  left:0;
  right:0;
  width: 100%;
  padding: 74px 0 44px;
  @media screen and (max-width: 758px){
    padding-bottom: 0;
  }
    background-color: var(--theme-colorscheme-background);
  //background-image: linear-gradient(90deg, $screen-background, 95%, $color1); //fb5607

}

.navbar-toggler {
  color: white;
  margin-right: 15px;
}

.brand-icon {
  height: 42px;
  width: 42px;
  margin: auto 10px;
}

.brand-logo {
  height: 42px;
  margin-left: 20px;
}

.error-message {
  margin: 20px;
}

.ds-screen-title{
  font-size: 2rem;
  margin: 20px;
  text-align: center;
}

.ds-screen-subtitle{
  font-size: 1.25rem;
  text-align: center;
  font-style: italic;
  margin-bottom: 10px;
}

.page-with-sidebar{
  display:flex;
  flex-direction: row;
}

.page-with-sidebar-content{
  width: 100%;
  padding-top: 20px;
}