
.ds-conversation-view-message {
  margin: 5px;
  padding: 10px;
}

.ds-conversation-view-message-user {
  background-color: var(--theme-colorscheme-aiconversationuser);
  color: var(--theme-colorscheme-on-aiconversationuser);
  margin-left: 20%;
  border-radius: 10px 10px 10px 0;
}

.ds-conversation-view-message-assistant {
  background-color: var(--theme-colorscheme-aiconversationai);
  color: var(--theme-colorscheme-on-aiconversationai);
  margin-right: 20%;
  border-radius: 10px 10px 0 10px;
}

.ds-conversation-view-message-system {
  background-color: var(--theme-colorscheme-aiconversationsystem);
  color: var(--theme-colorscheme-on-aiconversationsystem);
  margin-right: 10%;
  margin-left: 10%;
  border-radius: 10px 10px 0 0;
}


.ds-conversation-view-message-wrapper {
  height: 100%;
  overflow-y: scroll !important;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid rgba(var(--theme-colorscheme-secondary-rgb), 0.5);
  border-radius: 5px;
  scroll-padding: 10px;

}

.ds-conversation-view-role{
  font-size: 8pt;
  text-align: right;
  font-style: italic;
  opacity: 0.7;
}

