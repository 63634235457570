.ds-panel{
  border-radius: 5px;
  padding: var(--theme-surface-padding);
  margin-bottom: 20px;
  @media screen and (max-width: 992px) {
    border: none;
    padding: 15px;
    border-radius: 0;
  }
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  background-color: var(--theme-colorscheme-surface);
  color: var(--theme-colorscheme-on-surface);
}

.ds-panel-header {
  margin-bottom: 20px;
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--theme-colorscheme-on-surface);
}

.ds-panel-header-left {
  text-align: left;
}

.ds-panel-header-center {
  text-align: center;
}
.ds-panel-header-right {
  text-align: right;
}

.ds-single-centered-panel{
  padding-top: 20px;
}