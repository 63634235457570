
.feature-experiment-data-panel{
  background-color: transparent;
  padding-top: 0;
  border: solid 1px var(--theme-grid-line-color);
  height: 800px;
  overflow-y: auto;
}

.feature-experiment-data-card-details{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.feature-experiment-data-card-footer{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: 0.8rem;
  margin-top: 10px;
}

.feature-experiment-data-card-message {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.feature-experiment-data-card {
  margin: 10px 0 10px;
  padding: 5px;
  @media screen and (max-width: 992px) {
    border: 1px solid var(--theme-panel-border-color);
    border-radius: 5px;
  }
}