.dashboard-number-card-content{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.dashboard-number-card-value{
  margin-left: 20px;
}
.dashboard-number-card-ofValue{
  font-size: 1.5rem;
  color: var(--theme-colorscheme-on-surface);
  margin-right: 20px;
}