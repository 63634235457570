
.get-experiment-data-wrapper{
  display: flex;
  width: 100%;
  flex-direction: row;
    align-content: flex-end;
  margin-bottom: 10px;
}

.feature-experiment-screen{
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.feature-experiment-prompt-input{
  height: 200px;
}

.feature-experiment-data-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.feature-experiment-results-panel {
  @media screen and (max-width: 992px) {
    margin: 0;
    padding: 0;
  }
}

.feature-experiment-data-button-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: -10px;
  margin-right: 10px;
  flex-grow: 1;
}