:root {
    --theme-colorscheme-primary: #254580; 
    --theme-colorscheme-primary-rgb: 37, 69, 128; 
    --theme-colorscheme-secondary: #257280; 
    --theme-colorscheme-secondary-rgb: 37, 114, 128; 
    --theme-colorscheme-background: #EEEEEE; 
    --theme-colorscheme-background-rgb: 238, 238, 238; 
    --theme-colorscheme-surface: #FAFAFA; 
    --theme-colorscheme-surface-rgb: 250, 250, 250; 
    --theme-colorscheme-error: #B00020; 
    --theme-colorscheme-error-rgb: 176, 0, 32; 
    --theme-colorscheme-on-primary: #ffffff; 
    --theme-colorscheme-on-primary-rgb: 255, 255, 255; 
    --theme-colorscheme-on-secondary: #ffffff; 
    --theme-colorscheme-on-secondary-rgb: 255, 255, 255; 
    --theme-colorscheme-on-background: #000000; 
    --theme-colorscheme-on-background-rgb: 0, 0, 0; 
    --theme-colorscheme-on-surface: #000000; 
    --theme-colorscheme-on-surface-rgb: 0, 0, 0; 
    --theme-colorscheme-on-error: #ffffff; 
    --theme-colorscheme-on-error-rgb: 255, 255, 255; 
    --theme-colorscheme-red: #B71C1C; 
    --theme-colorscheme-red-rgb: 183, 28, 28; 
    --theme-colorscheme-on-red: #ffffff; 
    --theme-colorscheme-on-red-rgb: 255, 255, 255; 
    --theme-colorscheme-orange: #FF521B; 
    --theme-colorscheme-orange-rgb: 255, 82, 27; 
    --theme-colorscheme-on-orange: #000000; 
    --theme-colorscheme-on-orange-rgb: 0, 0, 0; 
    --theme-colorscheme-yellow: #FFEB3B; 
    --theme-colorscheme-yellow-rgb: 255, 235, 59; 
    --theme-colorscheme-on-yellow: #000000; 
    --theme-colorscheme-on-yellow-rgb: 0, 0, 0; 
    --theme-colorscheme-green: #1B5E20; 
    --theme-colorscheme-green-rgb: 27, 94, 32; 
    --theme-colorscheme-on-green: #ffffff; 
    --theme-colorscheme-on-green-rgb: 255, 255, 255; 
    --theme-colorscheme-blue: #0D47A1; 
    --theme-colorscheme-blue-rgb: 13, 71, 161; 
    --theme-colorscheme-on-blue: #ffffff; 
    --theme-colorscheme-on-blue-rgb: 255, 255, 255; 
    --theme-colorscheme-purple: #B8B3E9; 
    --theme-colorscheme-purple-rgb: 184, 179, 233; 
    --theme-colorscheme-on-purple: #000000; 
    --theme-colorscheme-on-purple-rgb: 0, 0, 0; 
    --theme-colorscheme-pink: #E91E63; 
    --theme-colorscheme-pink-rgb: 233, 30, 99; 
    --theme-colorscheme-on-pink: #000000; 
    --theme-colorscheme-on-pink-rgb: 0, 0, 0; 
    --theme-colorscheme-aiconversationsystem: #A5D6A7; 
    --theme-colorscheme-aiconversationsystem-rgb: 165, 214, 167; 
    --theme-colorscheme-on-aiconversationsystem: #000000; 
    --theme-colorscheme-on-aiconversationsystem-rgb: 0, 0, 0; 
    --theme-colorscheme-aiconversationai: #EEEEEE; 
    --theme-colorscheme-aiconversationai-rgb: 238, 238, 238; 
    --theme-colorscheme-on-aiconversationai: #000000; 
    --theme-colorscheme-on-aiconversationai-rgb: 0, 0, 0; 
    --theme-colorscheme-aiconversationuser: #90CAF9; 
    --theme-colorscheme-aiconversationuser-rgb: 144, 202, 249; 
    --theme-colorscheme-on-aiconversationuser: #000000; 
    --theme-colorscheme-on-aiconversationuser-rgb: 0, 0, 0; 
    --theme-colorscheme-gridline: #5b6a86; 
    --theme-colorscheme-gridline-rgb: 91, 106, 134; 
    --theme-modal-close-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill='%23254580'%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
    --theme-accordion-btn-icon: url("data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23000000\'><path fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/></svg>");
    --theme-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23ffffff\'%3e%3cpath fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/%3e%3c/svg%3e");
}

[data-bs-theme='dark'] {    --theme-colorscheme-primary: #5c9fd7; 
    --theme-colorscheme-primary-rgb: 92, 159, 215; 
    --theme-colorscheme-secondary: #5cd7d1; 
    --theme-colorscheme-secondary-rgb: 92, 215, 209; 
    --theme-colorscheme-background: #4c4c4c; 
    --theme-colorscheme-background-rgb: 76, 76, 76; 
    --theme-colorscheme-surface: #212121; 
    --theme-colorscheme-surface-rgb: 33, 33, 33; 
    --theme-colorscheme-error: #B00020; 
    --theme-colorscheme-error-rgb: 176, 0, 32; 
    --theme-colorscheme-on-primary: #000000; 
    --theme-colorscheme-on-primary-rgb: 0, 0, 0; 
    --theme-colorscheme-on-secondary: #000000; 
    --theme-colorscheme-on-secondary-rgb: 0, 0, 0; 
    --theme-colorscheme-on-background: #ffffff; 
    --theme-colorscheme-on-background-rgb: 255, 255, 255; 
    --theme-colorscheme-on-surface: #F5F5F5; 
    --theme-colorscheme-on-surface-rgb: 245, 245, 245; 
    --theme-colorscheme-on-error: #ffffff; 
    --theme-colorscheme-on-error-rgb: 255, 255, 255; 
    --theme-colorscheme-red: #E57373; 
    --theme-colorscheme-red-rgb: 229, 115, 115; 
    --theme-colorscheme-on-red: #000000; 
    --theme-colorscheme-on-red-rgb: 0, 0, 0; 
    --theme-colorscheme-orange: #FF521B; 
    --theme-colorscheme-orange-rgb: 255, 82, 27; 
    --theme-colorscheme-on-orange: #000000; 
    --theme-colorscheme-on-orange-rgb: 0, 0, 0; 
    --theme-colorscheme-yellow: #FFC145; 
    --theme-colorscheme-yellow-rgb: 255, 193, 69; 
    --theme-colorscheme-on-yellow: #000000; 
    --theme-colorscheme-on-yellow-rgb: 0, 0, 0; 
    --theme-colorscheme-green: #81C784; 
    --theme-colorscheme-green-rgb: 129, 199, 132; 
    --theme-colorscheme-on-green: #000000; 
    --theme-colorscheme-on-green-rgb: 0, 0, 0; 
    --theme-colorscheme-blue: #97c6e8; 
    --theme-colorscheme-blue-rgb: 151, 198, 232; 
    --theme-colorscheme-on-blue: #000000; 
    --theme-colorscheme-on-blue-rgb: 0, 0, 0; 
    --theme-colorscheme-purple: #B8B3E9; 
    --theme-colorscheme-purple-rgb: 184, 179, 233; 
    --theme-colorscheme-on-purple: #000000; 
    --theme-colorscheme-on-purple-rgb: 0, 0, 0; 
    --theme-colorscheme-pink: #F06292; 
    --theme-colorscheme-pink-rgb: 240, 98, 146; 
    --theme-colorscheme-on-pink: #000000; 
    --theme-colorscheme-on-pink-rgb: 0, 0, 0; 
    --theme-colorscheme-aiconversationsystem: #00796B; 
    --theme-colorscheme-aiconversationsystem-rgb: 0, 121, 107; 
    --theme-colorscheme-on-aiconversationsystem: #ffffff; 
    --theme-colorscheme-on-aiconversationsystem-rgb: 255, 255, 255; 
    --theme-colorscheme-aiconversationai: #757575; 
    --theme-colorscheme-aiconversationai-rgb: 117, 117, 117; 
    --theme-colorscheme-on-aiconversationai: #ffffff; 
    --theme-colorscheme-on-aiconversationai-rgb: 255, 255, 255; 
    --theme-colorscheme-aiconversationuser: #1976D2; 
    --theme-colorscheme-aiconversationuser-rgb: 25, 118, 210; 
    --theme-colorscheme-on-aiconversationuser: #ffffff; 
    --theme-colorscheme-on-aiconversationuser-rgb: 255, 255, 255; 
    --theme-colorscheme-gridline: #4c4c4c; 
    --theme-colorscheme-gridline-rgb: 76, 76, 76; 
    --theme-modal-close-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill='%235c9fd7'%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
    --theme-accordion-btn-icon: url("data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23F5F5F5\'><path fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/></svg>");
    --theme-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'%23000000\'%3e%3cpath fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/%3e%3c/svg%3e");
    label{color: var(--theme-colorscheme-on-surface);}
}

