
.footer-copyright {
  font-size: 0.8rem;
  margin: 0;
  padding-left: 10px;


}

.footer-link {
  font-size: 0.8rem;
  color: white;
  display: inline-block;
  margin: 0 0 0 5px;
  padding-left: 10px;
  border-left: 1px solid white;


}

.footer-container {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--theme-colorscheme-on-surface);
  background-color: var(--theme-colorscheme-surface);
  @media screen and (max-width: 768px) {
    display: none;
  }
}