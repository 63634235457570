

.usage-alert-container{
    display: grid;
  flex-direction: row;
  width: 100%;
  i {
    justify-self: flex-end;
  }
}

.ds-usage-tab-title {
  display: flex;
  justify-content: space-between;
}